import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import app from '../firebase';
import { getDatabase, ref, get } from "firebase/database";
import "./Common.css";

const BlogDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      const db = getDatabase(app);
      const dbRef = ref(db, `blog/data/${id}`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        setData(snapshot.val());
      } else {
        console.error("No data available for the specified blog ID");
      }
      setLoading(false);
    };



    fetchBlogDetails();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No blog found</div>;
  }

  return (
    <>
      <div className="main-img">
        <img src={data.BlogMainImage} className="image_blog" alt="main" />
      </div>
      <div>
        <h1>{data.BlogName}</h1>
      </div>
      <div>{data.BlogDescription}</div>
      <div className="multiple-img">
        <img src={data.BlogSubImgOne} alt="sub1" />
        <img src={data.BlogSubImgTwo} alt="sub2" />
        <img src={data.BlogSubImgThree} alt="sub3" />
      </div>
    </>
  );
};

export default BlogDetail;
