import React, { useEffect, useState } from "react";
import app from '../../firebase';
import { getDatabase, ref, get } from "firebase/database";
import "../Blogcard/blogcard.css";
import { useNavigate } from 'react-router-dom';

const BlogCard = () => {
  const navigate = useNavigate();
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const db = getDatabase(app);
      const dbRef = ref(db, "blog/data");
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Store blogs with their Firebase IDs
        const blogs = Object.entries(data).map(([id, blog]) => ({ id, ...blog })).reverse();
        setBlogPosts(blogs);
      } else {
        console.error("No data available");
      }
    };

    fetchData();
  }, []);

  return (

    <ul className="flex_card_portion">
      {blogPosts.map((item) => (
        <li key={item.id}>
            <div className='service_card' key={item.id}>
              <div className='icon_content_portion'>
                <div className='icon_service'>
                  <img src={item.BlogMainImage} loading="lazy" alt='blogcard' className='icon_class' />
                </div>
                <h1 className='service_heading'>{item.BlogName}</h1>
              </div>
              <p className='service_paragraph'>{item.BlogDescription}</p>
              <button className="readmore" onClick={() => navigate(`/blog/${item.id}`)}>Read More</button>
            </div>
        </li>
      ))}
    </ul>


  );
};

export default BlogCard;
